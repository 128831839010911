import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function usePaymentContractsList(root) {
  // Use toast
  const toast = useToast()

  const refPaymentContractsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'created_at', label: 'Creation date', sortable: true },
    { key: 'updated_at', label: 'Last modified', sortable: true },
    { key: 'created_by', label: 'Created by', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('paymentContractsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refPaymentContractsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-payment-contracts', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-payment-contracts', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-payment-contracts', 'searchQuery', searchQueryVal)
        }, 250)
      }
    })

  const fetchPaymentContractsList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }

    if (query.searchQuery) payloadData.name = query.searchQuery

    store
      .dispatch('app-payment-contracts/fetchPaymentContractsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Payment Contracts' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteItem = ID => axios
    .delete(`auth/payment-contract/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Payment Contract',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Payment Contract',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPaymentContractsList,
    deleteItem,
    refetchData,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refPaymentContractsListTable,
  }
}
